<template>
  <div>
    <a
      id="popover-button-event"
      class="floating-icon d-flex align-items-center justify-content-center"
      href="#"
      @click.prevent="onOpen"
    >
      <b-icon-chat v-if="collapsed" font-scale="2"> </b-icon-chat>
      <b-icon-x v-else font-scale="2"> </b-icon-x>
    </a>

    <b-card
      class="floating-menu"
      v-if="!collapsed"
      title="Request Information"
      style="overflow-y: auto"
    >
      <div v-if="success" class="mt-5">
        <div class="d-flex justify-content-center my-3">
          <b-icon-check-circle variant="success" font-scale="3">
          </b-icon-check-circle>
        </div>
        <h6 class="text-center">
          Thank you for inquiring. We'll get back to you as soon as possile.
        </h6>

        <div class="d-flex justify-content-center my-3">
          <b-btn variant="primary" @click.prevent="onClose">
            <span>Close</span>
          </b-btn>
        </div>
      </div>
      <b-form
        @submit.prevent="onSubmit"
        style="padding-left: 5px; padding-right: 15px"
        class="pb-3"
        v-else
      >
        <b-form-group
          label="Program"
          label-for="program"
          label-class="form-label"
        >
          <v-select
            id="program"
            class="form-control v-select-custom"
            label="title"
            v-model="program"
            :reduce="(program) => program.id"
            placeholder="Select Program"
            :options="allPrograms"
            :loading="areProgramsLoading"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                v-bind="attributes"
                v-on="events"
                :required="!program"
              />
            </template>

            <template slot="option" slot-scope="option">
              <div class="d-flex align-items-center">
                <b-avatar
                  :title="true"
                  title-class="bg-transparent"
                  no-link
                  slot="aside"
                  size="sm"
                  square
                >
                  <b-img
                    :src="option.image"
                    class="img-fluid"
                    alt="Program"
                    v-if="option.image"
                  />
                  <b-icon-award v-else></b-icon-award>
                </b-avatar>
                <span class="ml-2">{{ option.title }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              {{
                truncate(option.title, { length: isMobSmallScreen ? 20 : 27 })
              }}
            </template>
          </v-select>
        </b-form-group>

        <b-form-group
          label="First Name"
          label-for="firstname"
          label-class="form-label"
        >
          <b-form-input
            id="firstname"
            placeholder="First Name"
            v-model.trim="firstName"
            required
          />
        </b-form-group>

        <b-form-group
          label="Last Name"
          label-for="lastname"
          label-class="form-label"
        >
          <b-form-input
            id="lastname"
            placeholder="Last Name"
            v-model.trim="lastName"
            required
          />
        </b-form-group>

        <b-form-group
          label="Email Address"
          label-for="email"
          label-class="form-label"
        >
          <b-form-input
            id="email"
            placeholder="Email Address"
            v-model="email"
            type="email"
            autocomplete="off"
            required
            :state="errorStates.userEmail"
          />
          <b-form-invalid-feedback>
            Email Already Exists
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Phone" label-for="phone" label-class="form-label">
          <b-form-input
            id="phone"
            placeholder="(888) 689 - 1235"
            v-mask="'(###) ### - ####'"
            v-model="phone"
            :state="errorStates.phone"
            required
          />
          <b-form-invalid-feedback>
            Invalid phone number. Please make sure that it's in correct format
            i.e. (XXX) XXX - XXXX.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <b-form-textarea
            rows="5"
            placeholder="Write a message to send."
            v-model="message"
            required
          ></b-form-textarea>
        </b-form-group>
        <b-form-group
          label="Permission to text?"
          label-for="permission_to_text"
          label-class="form-label"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            id="permission_to_text"
            v-model="permission_to_text"
            :options="yesNoOptions"
            :aria-describedby="ariaDescribedby"
            required
            :state="errorStates.permission_to_text"
          ></b-form-radio-group>
          <b-form-invalid-feedback
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>
        <div class="d-flex justify-content-end mb-2">
          <b-btn
            variant="primary"
            :disabled="isLoading"
            type="submit"
            style="width: 150px"
            class="btn-normal"
          >
            <b-spinner label="Loading" small v-if="isLoading"></b-spinner>
            <span v-else>Submit Request</span>
          </b-btn>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { get, truncate } from "lodash";
import axios from "axios";
import {
  BIconChat,
  BIconX,
  BIconAward,
  BIconCheckCircle,
  BFormInvalidFeedback,
  BFormInput,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormRadioGroup,
  BAvatar,
  BButton,
  BCard,
  BSpinner,
  BImg,
} from "bootstrap-vue";

export default {
  props: {
    schKey: { type: [Number, String] },
    widgetBackground: { type: String, default: "#214e6f" },
    iconColor: { type: String, default: "#ffffff" },
  },

  components: {
    BIconChat,
    BIconX,
    BIconAward,
    BIconCheckCircle,
    BFormInvalidFeedback,
    BFormInput,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormRadioGroup,
    BAvatar,
    BCard,
    BSpinner,
    BImg,
    "b-btn": BButton,
  },

  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
      permission_to_text: null,
      program: null,
      errorStates: {
        userEmail: null,
        phone: null,
        permission_to_text: null,
      },
      yesNoOptions: [
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],
      isLoading: false,
      school: null,
      areProgramsLoading: false,
      collapsed: true,
      allPrograms: [],
      totalPrograms: 0,
      schoolId: null,
      PHONE_FORMAT_REGEX: /^\(\d{3}\) \d{3} - \d{4}$/,

      reqError: false,
      success: false,

      windowWidth: window.innerWidth,
    };
  },

  watch: {
    schKey: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.schoolId = value;
          this.fetchPrograms();
        }
      },
    },
  },

  computed: {
    isMobSmallScreen() {
      return this.windowWidth <= 480;
    },
  },

  methods: {
    get,
    truncate,

    titleize(value) {
      return value
        .toLowerCase()
        .replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
    },

    async fetchPrograms() {
      this.areProgramsLoading = true;

      const params = { school: this.schKey };
      const res = await axios.get(`api/programs/school-offered-programs/`, {
        params,
      });

      this.allPrograms = res.data.data;
      this.totalPrograms = res.data.count;

      this.areProgramsLoading = false;
    },

    onOpen() {
      this.collapsed = !this.collapsed;
    },

    onClose() {
      this.success = false;
      this.collapsed = true;
    },

    async onSubmit() {
      this.isLoading = true;

      if (!this.PHONE_FORMAT_REGEX.test(this.phone)) {
        this.errorStates.phone = false;
        this.isLoading = false;
        return;
      }

      try {
        const data = {
          email_message: this.email,
          first_name: this.titleize(this.firstName),
          last_name: this.titleize(this.lastName),
          email: this.email,
          phone: this.phone,
          is_text_allowed: this.permission_to_text,
          program: this.program,
          school: this.schKey,
          comments: this.message,
        };
        await axios.post(`api/contact_program_info/`, data);
        this.success = true;
      } catch (err) {
        this.reqError = true;
      }
      this.isLoading = false;
    },
  },

  async beforeMount() {
    document.documentElement.style.setProperty(
      "--widget-color",
      this.iconColor || "#fffff"
    );
    document.documentElement.style.setProperty(
      "--widget-background",
      this.widgetBackground || "#214e6f"
    );
  },

  async mounted() {
    await this.fetchPrograms();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/inquiry-widget.scss";
</style>